
$font_stack_1: 'Montserrat', sans-serif;
$font_stack_2: 'Montserrat', sans-serif;

$white_color: #fff;
$black_color: #000;
$menu_color: #fff;
$icon_color: #ff6426;
$common_color: #ff6426;

$btn_bg: #ea5267;
$base_color: #ea5267;
$btn_hover: #0a0b4d;
$section_bg: #0d0f5b;
$section_bg_1: #0a0b4d;
$heading_color: #0a0b4d;
$heading_color2: #ff8b23;
$p_color: #fff;
$font_1: #777777;
$font_2: #646464;
$font_3: #7f7f7f;
$font_4: #8a8a8a;
$font_5: #999999;
$font_9: #999999;
$font_6: #5b5b5b;
$border_color: #fdcb9e;
$footer_bg: #303030;
$sidebar_bg: #fbf9ff;

$small_mobile:'(max-width: 575px)';
$large_mobile: 'only screen and (min-width: 576px) and (max-width: 767px)';
$tab_device:'only screen and (min-width: 768px) and (max-width: 991px)';
$medium_device : 'only screen and (min-width: 991px) and (max-width: 1200px)';
$tab: '(max-width: 991px)';
$xs_mobile:'(max-width: 420px)';
$sm_mobile:'only screen and (min-width: 421px) and (max-width: 575px)';
$big_screen:'only screen and (min-width: 1200px) and (max-width: 1440px)';
$extra_big_screen: 'only screen and (min-width: 1440px) and (max-width: 1658px)';