/******** portfolio part css start **********/
.portfolio_part{
    position: relative;
    z-index: 1;
    .grid-item{
        position: relative;
        z-index: 1;
        text-align: center;
        &:after{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $base_color;
            content: "";
            opacity: 0;
            z-index: 2;
            @include transform_time(.5s);
        }
        .portfolio_hover_text{
            i{
                color: $white_color;
                font-size: 25px;
                position: absolute;
                top: 48%;
                left: 0;
                right: 0;
                margin: 0 auto;
                padding: 10px;
                z-index: 9;
                opacity: 0;
                @include transform_time(.5s);
            }
        }
        &:hover{
            &:after{
                opacity: .5;
                padding: 10px;
            }
            .portfolio_hover_text{
                i{
                    opacity: 1;
                }
            }
        }
    }
    
    .grid-sizer,
    .grid-item { 
        width: 33.33%; 
        @media #{$tab}{
            width: 50%;
        }
        @media #{$small_mobile}{
            width: 100%;
        }
    }
    .grid-item { 
        float: left;
        height: 350px;
        @media #{$tab}{
            height: auto;
        }
        @media #{$small_mobile}{
            height: auto;
        }
    }
    .big_weight{
        width: 66.66%;
        @media #{$tab}{
            width: 50%;
        }
        @media #{$small_mobile}{
            width: 100%;
        }
    }
    .big_height{
        height: 700px;
        @media #{$tab}{
            height: auto;
        }
        @media #{$small_mobile}{
            height: auto;
        }
    }
    img{
        width: 100%;
        height: 100%;
    } 
    @media #{$small_mobile}{
        .btn_2{
            margin-bottom: 30px;
        }
        
    }
    @media #{$large_mobile}{
        .btn_2{
            margin-bottom: 30px;
        }
    }
    @media #{$tab_device}{
        .btn_2{
            margin-bottom: 30px;
        }
    }

}
.project_details{
    ul{
        margin-top: 15px;
        li{
            margin-bottom: 8px;
            a{
                color: #0a0b4d;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
}