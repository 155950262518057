//--------- start footer Area -------------//
.footer_Part{
	background-color: $section_bg_1;
	position: relative;
	z-index: 1;
	&:after{
        width: 100%;
        height: 100%;
        position: absolute;
        content: "";
        right: 0;
        background-size: contain;
        top: 0;
        z-index: -1;
        background: url(../img/footer_overlay_2.png) top right no-repeat;

    }
    &:before{
        width: 100%;
        height: 100%;
        position: absolute;
        content: "";
        right: 0;
        background-size: contain;
        top: 0;
        z-index: -1;
        background: url(../img/footer_overlay_1.png) top right no-repeat;

    }
	.footer_text{
		margin-bottom: 70px;
		@media #{$tab}{
			margin-bottom: 15px;
		}
		span{
			color: $base_color;
			text-transform: uppercase;
			margin-bottom: 14px;
			display: inline-block;
		}
		h2{
			font-size: 36px;
			color: $white_color;
			@media #{$tab}{
				font-size: 25px;
			}
		}
		@media #{$tab}{
			text-align: center;
		}
	}
	.footer_btn{
		text-align: right;
		@media #{$tab}{
			text-align: center;
			margin-bottom: 30px;
		}
	}
	.footer_menu{
		a{
			color: $white_color;
			margin-right: 15px;
		}
		@media #{$tab}{
			text-align: center;
		}
	}
	.social_icon{
		text-align: right;
		@media #{$tab}{
			text-align: center;
			margin-top: 30px;
		}
		a{
			color: $white_color;
			margin-left: 20px;
		}
	}
	.copyright_part{
		padding: 140px 0 40px;
		@media #{$tab}{
			padding: 70px 0 40px;
		}
		// @include #{$tab}{
		// 	padding: 70px 0 40px;
		// }
		// @include #{$medium_device}{
		// 	padding: 80px 0 40px;
		// }
	}
}
//--------- end footer Area -------------//