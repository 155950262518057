/* Main Button Area css
============================================================================================ */
.submit_btn{
	width: auto;
	display: inline-block;
	background: $white_color;
	padding: 0px 50px;
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	line-height: 50px;
	border-radius: 5px;
	outline: none !important;
	box-shadow: none !important;
	text-align: center;
	border: 1px solid $border_color;
	cursor: pointer;
	@include transform_time(0.5s);
	&:hover{
		background: $section_bg;
		color: $white_color;

		
	}
}
.btn_1{
	display: inline-block;
	padding: 13.5px 45px;
	background-color: $base_color;
	font-size: 14px;
	color: $white_color;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
	text-transform: capitalize;
	border: 1px solid transparent;
	&:hover{
		color: $white_color !important;
		background: $section_bg !important;
		box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3)
	}
	@media #{$small_mobile}{
		padding: 10px 30px;
		margin-top: 25px;
	}
	@media #{$large_mobile}{
		padding: 10px 30px;
		margin-top: 30px;
	}
	@media #{$tab_device}{
		padding: 10px 30px;
		margin-top: 30px;
	}
	@media #{$medium_device}{
	
	}

}
.btn_2{
	display: inline-block;
	padding: 12.5px 45px;
	font-size: 14px;
	font-weight: 500;
	@include transform_time(.5s);
	text-transform: capitalize;
	color: $base_color;
	border: 1px solid $base_color;
	font-family: $font_stack_2;
	&:hover{
		background-color: $white_color;
		color: $black_color;
	}
	@media #{$small_mobile}{
		padding: 9.5px 28px;
		margin-top: 25px;
	}
	@media #{$large_mobile}{
		padding: 9.5px 28px;
	}
	@media #{$tab_device}{
		padding: 9.5px 28px;
	}
	@media #{$medium_device}{
	
	}
}
.BG {
	
	position: absolute;
	left: 881px;
	top: 2664px;
	width: 140px;
	height: 44px;
	z-index: 428;
  }
  
.btn_3{
	display: inline-block;
	padding: 12px 35px;
	font-size: 14px;
	font-weight: 400;
	@include transform_time(.5s);
	text-transform: capitalize;
	color: $white_color;
	background-color: rgb(48, 56, 59);
	&:hover{
		background-color: rgb(248, 28, 28);
		box-shadow: 0px 15px 20px 0px rgba(248, 28, 28, 0.1);
		color: $white_color;
	}
	@media #{$small_mobile}{
		padding: 9.5px 28px;
		margin-top: 25px;
	}
	@media #{$large_mobile}{
		padding: 9.5px 28px;
	}
	@media #{$tab_device}{
		padding: 9.5px 28px;
	}
	@media #{$medium_device}{
	
	}
}
.single_page_btn{
	display: inline-block;
	padding: 9.5px 28px;
	border-radius: 50px;
	background-color: #ff6426;
	font-size: 14px;
	font-weight: 400;
	color: $white_color;
	@include transform_time(.5s);
	text-transform: capitalize;
	border: 2px solid transparent;
	&:hover{
		color: $common_color;
		background-color: $white_color;
		border: 2px solid $common_color;
	}
	@media #{$small_mobile}{
		padding: 9.5px 28px;
	}
	@media #{$large_mobile}{
		padding: 9.5px 28px;
	}
	@media #{$tab_device}{
		padding: 9.5px 28px;
	}
	@media #{$medium_device}{
	
	}

}
.btn_4{
	display: inline-block;
	font-size: 14px;
	color: $white_color;
	text-transform: capitalize;
	@include transform_time(.5s);
	padding: 14.5px 43px;
	background-color: $btn_bg;
	border: 2px solid $btn_bg;
	&:hover{
		color: $btn_bg !important;
		background-color: transparent;
	}
	
	@media #{$small_mobile}{
		
	}
	@media #{$large_mobile}{
		
	}
	@media #{$tab_device}{
		
	}
	@media #{$medium_device}{
	
	}
}

/* End Main Button Area css
============================================================================================ */