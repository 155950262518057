/**************** extend css start ****************/

// %custom_btn_bg_1{
//     background-image: linear-gradient(to left, rgb(171,118,54) 0%, rgb(215,175,126) 100%, rgb(171,118,54) 0%);  
// }
%custom_btn_bg_1{
    background-image: linear-gradient(to left, #ab7636 0%, #d7af7e 49%, #ab7636 100%);  
}

%custom_btn_bg_2{
    background-image: -webkit-linear-gradient( 131deg , #feb47b, #ff7e5f, #ff7e5f, #ff7e5f);
    background-image: -o-linear-gradient( 131deg , #feb47b, #ff7e5f, #ff7e5f, #ff7e5f);
    background-image: linear-gradient( 131deg , #feb47b, #ff7e5f, #ff7e5f, #ff7e5f);  
}
%custom_btn_bg_3{
    background: -moz-linear-gradient( 90deg, rgb(173,35,254) 0%, rgb(173,35,254) 0%, rgb(250,96,199) 100%);
    background: -webkit-linear-gradient( 90deg, rgb(173,35,254) 0%, rgb(173,35,254) 0%, rgb(250,96,199) 100%);
    background: -ms-linear-gradient( 90deg, rgb(173,35,254) 0%, rgb(173,35,254) 0%, rgb(250,96,199) 100%);
    box-shadow: 0px 8px 15px 0px rgba(180, 41, 248, 0.25);
    
}
%rank_bg{
    background: -moz-linear-gradient( 16deg, rgb(250,113,205) 0%, rgb(181,77,243) 100%);
    background: -webkit-linear-gradient( 16deg, rgb(250,113,205) 0%, rgb(181,77,243) 100%);
    background: -ms-linear-gradient( 16deg, rgb(250,113,205) 0%, rgb(181,77,243) 100%);
    box-shadow: 0px 10px 20px 0px rgba(196, 113, 245, 0.3);
}
  
%overlay_bg{
    background: -moz-linear-gradient( 16deg, rgb(250,113,205) 0%, rgb(181,77,243) 100%);
    background: -webkit-linear-gradient( 16deg, rgb(250,113,205) 0%, rgb(181,77,243) 100%);
    background: -ms-linear-gradient( 16deg, rgb(250,113,205) 0%, rgb(181,77,243) 100%);
}
%pricing_btn_bg_bg {
    background: -moz-linear-gradient( 90deg, rgb(173,35,254) 0%, rgb(250,96,199) 100%);
    background: -webkit-linear-gradient( 90deg, rgb(173,35,254) 0%, rgb(250,96,199) 100%);
    background: -ms-linear-gradient( 90deg, rgb(173,35,254) 0%, rgb(250,96,199) 100%);
    box-shadow: 0px 8px 15px 0px rgba(180, 41, 248, 0.25);
}
  
%icon_bg{
    background: -moz-linear-gradient( 45deg, rgb(173,35,254) 0%, rgb(250,96,199) 100%);
    background: -webkit-linear-gradient( 45deg, rgb(173,35,254) 0%, rgb(250,96,199) 100%);
    background: -ms-linear-gradient( 45deg, rgb(173,35,254) 0%, rgb(250,96,199) 100%);
}

/**************** extend css start ****************/
